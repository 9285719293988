<template>
    <div class="first-step">
        <div class="import-tips">
            <p class="tips1">
                <img
                    :src="helpUrl"
                    alt=""
                    class="help-img"
                />导入方法
            </p>
            <p>直接上传已有的账单数据，下一步建立对应关系</p>
            <p>
                或是
                <el-button
                    type="text"
                    @click="download"
                >
                    <img
                        :src="downloadUrl"
                        alt=""
                        class="download-img"
                    />
                    下载模板
                </el-button>
                并按照模板填充数据，上传填好的文件
            </p>
            <div class="filter_disf">
                <el-form
                    :model="importForm"
                    label-width="80px"
                    :rules="priceFormRules"
                    :show-message="false"
                    ref="setPriceRef"
                >
                    <el-row>
                        <el-col :span="10">
                            <el-form-item
                                label="项目名称"
                                prop="title"
                                :required="true"
                            >
                                <el-input
                                    v-model="importForm.title"
                                    placeholder="请输入项目名称"
                                    :maxlength="30"
                                    show-word-limit
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4"></el-col>
                        <el-col :span="10">
                            <el-form-item
                                label="就餐日期"
                                prop="dataArr"
                            >
                                <el-date-picker
                                    :style="{width: '270px', height: '36px'}"
                                    v-model="importForm.dataArr"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="选择开始日期"
                                    end-placeholder="选择结束日期"
                                    value-format="yyyy-MM-dd"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
        <div
            class="excel-wrapper"
            :class="[
                importForm.file !== '' ? 'active-excel' : 'inactive-excel',
            ]"
        >
            <div class="fileName">{{ importForm.file.name }}</div>
            <i
                class="el-icon-circle-close cancel-img"
                @click="delFile"
            ></i>
        </div>
        <div class="upload-wrapper">
            <el-upload
                ref="upload"
                :action="action"
                :headers="headers"
                accept=".xlsx,.xls"
                :on-change="uploadChange"
                :on-success="handleUpload"
                :with-credentials="true"
                :auto-upload="false"
                :show-file-list="false"
                :limit="1"
            >
                <el-button
                    v-if="importForm.file"
                    type="primary"
                    plain
                    @click.stop="limitTip"
                    >上传文件</el-button
                >
                <el-button
                    v-else
                    type="primary"
                    plain
                    >上传文件</el-button
                >
            </el-upload>
            <div>
                <p class="upload-tips">
                    文件后缀名必须为xls或xlsx（即Excel格式)
                </p>
                <p class="upload-tips">文件大小不得大于10M。</p>
            </div>
        </div>
        <div style="text-align: center">
            <el-button
                type="primary"
                @click="next"
                >下一步</el-button
            >
            <el-button
                type="reset"
                @click="cancel"
                >取消</el-button
            >
        </div>
    </div>
</template>

<script>
import {debounce} from "common-local";
import {getToken} from "@/libs/auth";
import {uploadAction} from "@/libs/settings";

export default {
    data() {
        return {
            priceFormRules: {
                title: [
                    {
                        max: 30,
                        required: true,
                        message: "请输入项目名称",
                        trigger: ["change", "blur"],
                    },
                ],
                dataArr: [
                    {
                        required: true,
                        message: "请选择就餐日期",
                        trigger: ["change", "blur"],
                    },
                ],
            },
        };
    },
    props: {
        importForm: {
            type: Object,
        },
    },
    computed: {
        helpUrl() {
            return require("@/assets/images/import/help.png");
        },
        downloadUrl() {
            return require("@/assets/images/import/download.png");
        },
        action() {
            return "/api/operate/common/uploadFile";
        },
        headers() {
            return {
                Authorization: getToken(),
            };
        },
    },
    methods: {
        limitTip() {
            this.$message.warning('当前限制上传一个文件')
        },
        delFile() {
            this.importForm.file = "";
            this.$refs.upload.clearFiles();
        },
        next: debounce(function () {
            if (!this.priceFormRules.dataArr) {
                return this.$message.warning("请选择就餐日期！");
            }
            this.$eventDispatch("next");
        }, 200),
        cancel: debounce(function () {
            this.$eventDispatch("close");
        }, 200),
        download: debounce(function () {
            this.$eventDispatch("download");
        }, 200),
        handleUpload: debounce(function (response, file, fileList) {
            console.log("success", file);
        }, 200),
        uploadChange: debounce(function (file) {
            const isImg = [".xlsx", ".xls"].includes(
                file.name.substring(file.name.lastIndexOf(".")),
            );
            const isLt10M = file.size / 1024 / 1024 < 10;
            const is50 = file.name.length < 30;
            if (!isImg) {
                this.$message.error(`上传文件格式仅支持xlsx和xls!`);
                this.$refs.upload.clearFiles();
                return false;
            }
            if (!is50) {
                this.$message.error("上传文件名称不能超过30个字符!");
                this.$refs.upload.clearFiles();
                return false;
            }
            if (!isLt10M) {
                this.$message.error(`上传文件的大小不能超过10M!`);
                this.$refs.upload.clearFiles();
                return false;
            }
            console.log(file, "file");
            this.importForm.file = file;
        }, 200),
        // getNewDate() {
        //     if (this.priceFormRules.length > 0) {
        //         this.priceFormRules.depositStartDate = this.priceFormRules[0];
        //         this.priceFormRules.depositEndDate = this.priceFormRules[1];
        //     }
        // },
    },
};
</script>

<style lang="scss" scoped>
.first-step {
    margin: 0 20%;
}

.import-tips {
    margin-top: 29px;
    text-align: left;
    height: 80px;
    font-size: 14px;
    color: #595959;
    line-height: 24px;

    .tips1 {
        display: flex;
        align-items: center;
        font-weight: bold;
    }
}
.filter_disf {
    font-size: 14px;
    height: 30px;
    width: 600px;
}

.help-img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.download-img {
    width: 11px;
    height: 13px;
    margin-right: 5px;
}

.active-excel {
    background-image: url("~@/assets/images/import/excell.png");
}

.inactive-excel {
    background-image: url("~@/assets/images/import/excel1.png");
}

.excel-wrapper {
    position: relative;
    margin: 40px 0 29px 0;
    width: 100%;
    height: 170px;
    background-color: #f5f7fa;
    background-repeat: no-repeat;
    background-size: 20%;
    background-position: center 20%;

    .fileName {
        position: absolute;
        bottom: 28px;
        left: 50%;
        transform: translate(-50%);
        white-space: nowrap;
        font-size: 18px;
        font-weight: bold;
        color: #595959;
    }

    .cancel-img {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        font-size: 20px;
        color: #c1cedb;

        &:hover {
            color: #faafaf;
        }
    }
}

.upload-wrapper {
    display: flex;
    margin-bottom: 60px;

    .upload-tips {
        margin-left: 16px;
        font-size: 12px;
        color: #a6a6a6;
        line-height: 22px;
    }
}
</style>
